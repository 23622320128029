import React from 'react'
import { EntityTypes } from '@src/constants/api'
import { AuditInterface, AuditSeverities } from '@src/interfaces/audit'
import { Stats } from '@src/interfaces/data'
import Stat from '@components/Stat/Stat'
import { useTableReturnType } from '@components/Table/hooks'
import { useTheme } from '@src/styles/theme'
import { getColor } from '@src/styles/colors'
import { Color, Flex, Text, Token } from '@revolut/ui-kit'

interface FindingsStatsProps {
  entity: EntityTypes
  table: useTableReturnType<AuditInterface, Stats, {}>
}

export const FindingsStats = ({ entity, table }: FindingsStatsProps) => {
  const theme = useTheme()

  let criticalNumber = 0
  let highNumber = 0
  let mediumNumber = 0
  let lowNumber = 0

  table.data.forEach(d => {
    switch (d.severity_v2?.id) {
      case AuditSeverities.Critical:
        criticalNumber += 1
        break
      case AuditSeverities.High:
        highNumber += 1
        break
      case AuditSeverities.Medium:
        mediumNumber += 1
        break
      case AuditSeverities.Low:
        lowNumber += 1
        break
      default:
    }
  })

  const totalNumber = criticalNumber + highNumber + lowNumber + mediumNumber

  return (
    <Flex mb="s-16">
      <Stat
        label="Critical"
        val={
          table.loading ? undefined : (
            <Text color={Token.color.red}>{criticalNumber}</Text>
          )
        }
      />
      <Stat
        label="High"
        val={
          table.loading ? undefined : (
            <Text color={Token.color.warning}>{highNumber}</Text>
          )
        }
        ml="s-32"
      />
      {entity !== EntityTypes.company && (
        <>
          <Stat
            label="Medium"
            val={
              table.loading ? undefined : (
                <Text color={getColor(theme, Color.ORANGE_OPAQUE_70)}>
                  {mediumNumber}
                </Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Low"
            val={
              table.loading ? undefined : (
                <Text color={getColor(theme, Color.GREY_TONE_50)}>{lowNumber}</Text>
              )
            }
            ml="s-32"
          />
          <Stat
            label="Total"
            val={
              table.loading ? undefined : (
                <Text color={Token.color.foreground}>{totalNumber}</Text>
              )
            }
            ml="s-32"
          />
        </>
      )}
    </Flex>
  )
}
